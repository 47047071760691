import React from "react"
import styled from "@emotion/styled"
import dimensions from "../../styles/dimensions"

const Grid = styled("div")`
  display: grid;
  grid-gap: 20px;
  @media (max-width: ${dimensions.maxwidthMobile}px) {
    grid-template-columns: 1fr !important;
  }
  margin-bottom: 10em !important;
`
const BlogGrid = ({ style, children }) => <Grid style={style}>{children}</Grid>

export default BlogGrid

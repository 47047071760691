import React from "react"
import styled from "@emotion/styled"

const BlogCardContainer = styled("div")`
  background: #ffffff;
  box-shadow: 0px 4px 76px 9px rgba(0, 0, 0, 0.06);
  border-radius: 15px;
  padding: 1.5em;

  h4 {
    margin-bottom: 1.45rem;
    font-weight: 600;
    line-height: 20px;
    font-size: 1.2em;
    border: 0;
  }
`
const BlogCard = ({ style, children }) => (
  <BlogCardContainer style={style}>{children}</BlogCardContainer>
)

export default BlogCard

import React from "react"
import styled from "@emotion/styled"

const ImgContainer = styled("div")`
  width: 10px;
`
const BlogImage = ({ imgsrc }) => (
  <ImgContainer>
    <img alt="try" src={imgsrc} />
  </ImgContainer>
)

export default BlogImage

import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"
import styled from "@emotion/styled"
import colors from "../styles/colors"
import dimensions from "../styles/dimensions"
import rehypeReact from "rehype-react"
import BlogGrid from "../components/_ui/BlogGrid"
import BlogImage from "../components/_ui/BlogImage"
import BlogCard from "../components/_ui/BlogCard"
// import slugs from "github-slugger"

const BlogContainer = styled("div")`
  .links {
    text-decoration: none;
  }
`
const BlogTOC = styled("div")``
const BlogHeader = styled("div")`
  img {
    width: 100%;
  }
`
const BlogHeaderOverview = styled("div")`
  display: flex;
  flex-direction: column;
  border-left: 1px solid ${colors.grey400};
  padding-left: 1em;
  align-items: center;

  div {
    width: fit-content;
    align-self: flex-end;
  }
  h4 {
    width: fit-content;
    display: flex;
    flex-direction: column;
  }
  @media (max-width: ${dimensions.maxwidthTablet}px) {
    border-left: 0px;
    padding-left: 0;

    div {
      align-self: flex-start;
    }
  }
`
const BlogHeaderText = styled("div")`
  display: grid;
  grid-gap: auto;
  grid-template-columns: 7fr 3fr;
  padding-top: 7em;
  padding-bottom: 7em;
  h1 {
    border-bottom: 0px;
    margin-top: ${rhythm(1)},
    margin-bottom: 0;
    display: flex;
    align-items: center;
    padding-right: 1em;
  }
  p {
    display: block,
    margin-bottom: ${rhythm(1)},
  }
  .custom-h4 {
    font-weight: 300;
  }
  @media (max-width: ${dimensions.maxwidthTablet}px) {
    grid-template-columns: 1fr;
  }
`
const BlogContentContainer = styled("div")`
  .full-width {
    position: relative !important;
    max-width: 100vw !important;
    width: 100vw !important;
    margin-left: -50vw !important;
    left: 50% !important;
    // height: auto !important;
    margin-bottom: 10em !important;

    @media (max-width: ${dimensions.maxwidthTablet}px) {
      margin-left: -50vw !important;
    }
    @media (max-width: ${dimensions.maxwidthMobile}px) {
      margin-left: -50vw !important;
    }
  }
  .highlight {
    background-color: currentColor;
    // border-bottom: 3px solid currentColor;
  }
  blockquote {
    font-weight: 600;
    font-style: italic;
    font-size: 1.5em;
    margin: 0 auto;
    text-align: center;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
  }
  h2 {
    margin-top: 7em;
    font-size: 0.9em;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
  }
  h3 {
    line-height: 1.2;
    font-size: 2em;
  }
  .table-of-contents {
    padding-bottom: 3em;
    ul {
      list-style-type: none;
      li {
        p {
          margin-bottom: 0;
        }
        margin-bottom: 0;
        ul {
          margin-top: 0;
        }
      }
    }
    a {
      text-decoration: none;
      color: ${colors.grey600};
    }
    a:hover {
      color: black;
    }
  }
`
const StyledLink = styled("div")`
  margin-top: 1em;
  a {
    color: ${colors.primary};
    text-decoration: none;
    font-weight: 600;
    font-size: 1em;
    border-bottom: 3px solid transparent;
    width: fit-content;
    padding-bottom: 0.1em;
  }
  a:hover {
    border-bottom: 2px solid ${colors.primary};
    transition: 100ms ease-in-out border-bottom;
  }
`
//thanks to: https://disaev.me/react-gatsby-table-of-contents/
// const preprocessHeading = h => {
//   const cleanValue = h.value
//     .replace(/<(\/)?[^>]+>/g, "")
//     .replace(/\s{2,}/g, " ")
//   return {
//     depth: h.depth,
//     value: cleanValue,
//     id: slugs.slug(cleanValue),
//   }
// }
const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    "blog-card": BlogCard,
    "blog-grid": BlogGrid,
    "blog-image": BlogImage,
  },
}).Compiler
const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const roles =
    post.frontmatter.roles &&
    post.frontmatter.roles.map(role => <div>{role}</div>)
  const deliverables =
    post.frontmatter.deliverables &&
    post.frontmatter.deliverables.map(deliverable => <div>{deliverable}</div>)
  const { previous, next } = pageContext

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <BlogContainer>
        <BlogTOC></BlogTOC>
        <BlogContentContainer>
          <article itemScope itemType="http://schema.org/Article">
            <BlogHeader>
              {post.frontmatter.thumbnail && (
                <img
                  src={post.frontmatter.thumbnail.childImageSharp.sizes.src}
                  alt={post.frontmatter.title}
                />
              )}
            </BlogHeader>
            <BlogHeaderText className="content">
              <h1 itemProp="headline">{post.frontmatter.title}</h1>
              <p
                style={{
                  ...scale(-1 / 5),
                }}
              >
                <BlogHeaderOverview>
                  <div>
                    <h4>
                      Timeline <br />
                      <p className="custom-h4">{post.frontmatter.timeline}</p>
                    </h4>
                    <h4>
                      Role & Responsibilities <br />
                      <p className="custom-h4">{roles}</p>
                    </h4>
                    <h4>
                      Deliverables <br />
                      <p className="custom-h4">{deliverables}</p>
                    </h4>
                  </div>
                </BlogHeaderOverview>
              </p>
            </BlogHeaderText>
            {/* <section
              dangerouslySetInnerHTML={{ __html: post.html }}
              itemProp="articleBody"
              className="content"
            /> */}
            <div className="content">{renderAst(post.htmlAst)}</div>
          </article>
        </BlogContentContainer>

        <StyledLink className="content">
          <ul
            style={{
              display: `flex`,
              // flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
            }}
          >
            <li className="left" style={{ width: "40%", textAlign: "right" }}>
              {previous && (
                <Link to={previous.fields.slug} rel="prev">
                  <span>←</span> {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li style={{ width: "40%" }}>
              {next && (
                <Link to={next.fields.slug} rel="next">
                  {next.frontmatter.title} <span>→</span>
                </Link>
              )}
            </li>
          </ul>
        </StyledLink>
      </BlogContainer>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      htmlAst
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        timeline
        roles
        deliverables
        thumbnail {
          childImageSharp {
            sizes(maxWidth: 2200, quality: 100) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
      headings {
        depth
        value
      }
    }
  }
`
